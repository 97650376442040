<template>
  <div class="row">
    <loader :show="isLoading" />
    <div class="form--title-section">
      <div class="left--part">
        <div class="top--text">
          {{ $t('addListing.sideTitle') }}
        </div>
        <div class="bottom--text">
          {{ $t('addListing.sideMsg') }}
        </div>
      </div>
      <div class="right--part">
        <img
          src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/static/form-banner.png"
        />
      </div>
    </div>
    <div class="col-12 col-md-4 listing--form-left">
      <!--      <client-only>-->
      <!--        <div v-if="$mq !== 'xs'">-->
      <!--          <div class="title&#45;&#45;text">-->
      <!--            {{ $t('addListing.sideTitle') }}-->
      <!--          </div>-->
      <!--          <div class="desc">-->
      <!--            {{ $t('addListing.sideMsg') }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </client-only>-->
      <v-stepper v-model="currentStep" vertical class="stepper--left">
        <v-stepper-step :complete="currentStep > 1" :step="1" class="stepper--step">
          {{ $t('addListing.step1') }}
        </v-stepper-step>
        <v-stepper-step :step="2" class="stepper--step">
          {{ $t('addListing.step2') }}
        </v-stepper-step>
      </v-stepper>
      <v-card class="listing--number-card" v-if="listingNumber">
        <div
          class="listing--number"
          style="position: relative"
          id="copyToClipboardListingNumber"
          @click="copyToClipboard(listingNumber)"
        >
          <div>
            {{ `${$t('general.listingNumber')}: ${listingNumber}` }}
          </div>
          <div>
            <v-icon>mdi-content-copy</v-icon>
          </div>
          <popover v-show="copyListingNumberSucceeded"></popover>
        </div>
      </v-card>
    </div>
    <div class="col-12 col-md-8 listing--form-right">
      <v-stepper v-model="currentStep" vertical class="stepper--right">
        <v-stepper-content step="1" class="stepper--content">
          <!--          Sect 1-->
          <sect1-form ref="sect1" v-show="currentStep === 1" />
          <!--                    <sect2-form ref="sect2" />-->
        </v-stepper-content>
        <v-stepper-content step="2" class="stepper--content">
          <!--          Sect 2-->
          <sect2-form ref="sect2" v-show="currentStep === 2" />
        </v-stepper-content>
      </v-stepper>
    </div>
    <!--    <modal-success />-->
  </div>
</template>

<script>
import Popover from '@/components/utils/popover.vue';

const Sect1Form = () => import('@/components/listing-form/sect1/index');
const Sect2Form = () => import('@/components/listing-form/sect2/index');
// const Sect2Form = () => import('@/components/listing-form/sect2/index');
const Loader = () => import('@/components/content-loading/page-loading.vue');
import { mapState } from 'vuex';

export default {
  name: 'listing-form',
  props: [
    'formProp', // add || edit
  ],
  components: {
    Popover,
    Sect1Form,
    Sect2Form,
    Loader,
  },
  data: () => ({
    copyListingNumberSucceeded: false,
  }),
  computed: {
    ...mapState({
      form: (state) => state.listingForm.form,
      listingNumber: (state) => state.listingForm.listingNumber,
      isLoading: (state) => state.listingForm.isLoading,
    }),
    currentStep: {
      get() {
        return this.$store.state.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
  },
  async created() {
    console.log('created');
    await this.$store.dispatch('listingForm/restoreInitialState');
    this.$store.commit('listingForm/SET_FORM', this.formProp);
    if (this.form === 'edit') {
      this.$store.commit('listingForm/SET_LISTING_UUID', this.$route.params.uuid);
    }
    try {
      this.setLoading(true);
      await this.$store.dispatch('listingForm/fetchBaseData');
      // eslint-disable-next-line no-empty
    } catch (e) {
      this.$router.replace('/product');
    } finally {
      this.setLoading(false);
    }
  },
  methods: {
    setLoading(value) {
      this.$store.commit('listingForm/SET_LOADING', value);
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyListingNumberStatus(true);
    },
    handleCopyListingNumberStatus(status) {
      this.copyListingNumberSucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copyListingNumberSucceeded = false;
      }, 2000);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.form--title-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 80px;
  border-radius: 30px;
  background-color: $color-beliruma-1-light;
  margin-bottom: 24px;
  @media #{$phones} {
    flex-wrap: wrap;
    padding: 16px 24px;
    margin: 16px 16px;
  }
  .left--part {
    width: calc(50% - 12px);
    @media #{$phones} {
      width: 100%;
    }
    .top--text {
      font-family: Poppins-Bold;
      font-size: $font-xdl;
      color: $color-text-black;
      margin-bottom: 8px;
      @media #{$phones} {
        font-size: $font-xxxl;
      }
    }
    .bottom--text {
      font-size: $font-xl;
      color: $color-text-black;
      @media #{$phones} {
        font-size: $font-md;
      }
    }
  }
  .right--part {
    width: calc(50% - 12px);
    @media #{$phones} {
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: contain;
      @media #{$phones} {
        max-height: 200px;
      }
    }
  }
}
</style>
