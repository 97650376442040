<template>
  <div class="my--popover">
    {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.my--popover {
  width: 240px;
  position: absolute;
  right: -248px;
  background-color: $color-white;
  padding: 4px 8px;
  border-radius: 12px;
  border: 1px solid $color-gray-1;
  box-shadow: $box-shadow;
  color: $color-text-black;
  font-size: $font-sm !important;
  font-family: Poppins;
  text-align: center;
  @media #{$phones} {
    left: 0;
    bottom: -36px;
  }
  strong {
    font-weight: normal;
    font-family: Poppins-SemiBold;
  }
}
</style>
